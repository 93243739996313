<script setup>
import {computed, defineProps, ref} from 'vue';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import {Form} from 'vee-validate';
import FormInput from '@/components/FormInput.vue';
import FormSelect from '@/components/FormSelect.vue';
import {gql} from '@apollo/client/core';
import notify from '@/notify';
import {t} from '@/i18n';
import bus from '@/bus.js';
import {useMutation} from '@vue/apollo-composable';
import validate from '@/validate';

const props = defineProps({
    currentFiduciary: {
        type: Object,
    },
});

const edit = ref(false);
const fiduForm = ref(null);

function handleEdit () {
    edit.value = true;
}

function handleCancel () {
    fiduForm.value.resetForm();
    edit.value = false;
}

const submitDisabled = computed(() => {
    return Object.keys(fiduForm.value.errors).length > 0;
});

async function handleSave (values) {
    const { mutate: updateEnvironment } = await useMutation(gql`
            mutation ($fiduciaryId: String, $input: FiduciaryUpdateInput!) {
                            updateFiduciary(fiduciaryId: $fiduciaryId, input: $input) {
                                data {
                                    name
                                    contactEmail
                                    language
                                }
                            }
                        }
        `);
    const result = (await updateEnvironment({
        fiduciaryId: props.currentFiduciary.id,
        input: {
            name: values.name,
            contactEmail: values.contactEmail,
            language: values.language,
        }})).data.updateFiduciary;

    if (result.errors?.length > 0) {
        validate.reportGQLFieldErrors(result.errors, fiduForm);
        return;
    }

    if (result.data) {
        const data = result.data;
        bus.emit('environmentUpdate', {id: props.currentFiduciary.id, name: data.name});
        props.currentFiduciary.name = data.name;
        props.currentFiduciary.language = data.language;
        props.currentFiduciary.contactEmail = data.contactEmail;
        notify.success(t('suc-env-modified'));
    } else {
        notify.error(t('err-unknown'));
    }
    edit.value = false;
}

</script>

<template>
    <div>
        <Form
            ref='fiduForm'
            id='fiduInfo'
            @submit='handleSave'
        >
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-fidu-info') }}
            </CustomTitle>
            <content-box>
                <div class='flex flex-row justify-end'>
                    <div v-if='!edit' class='client-subppage-header__actions__edit'>
                        <FriendlyButton
                            label='btn-env-edit'
                            symbol='pencil'
                            :action='handleEdit'
                            square
                            extra-small
                            no-margin
                        />
                    </div>
                    <template v-else>
                        <FriendlyButton
                            label='btn-cancel'
                            type='reset'
                            :action='() => { handleCancel() }'
                            symbol='times'
                            secondary
                            square
                            extra-small
                            no-margin
                            class='mr-2'
                        />
                        <FriendlyButton
                            label='btn-save'
                            :disabled='submitDisabled'
                            symbol='check'
                            type='submit'
                            square
                            extra-small
                            no-margin
                        />
                    </template>
                </div>
                <div class='flex'>
                    <div class='w-full'>
                        <div class='w-full px-6'>
                            <FormInput
                                :value='currentFiduciary.name'
                                name='name'
                                ref='name'
                                :label='$t("lbl-name")'
                                :edit='edit'
                                rules='required|max:160'
                                id='name'
                                class='mb-4'
                            />
                            <FormInput
                                :value='currentFiduciary.softwareName'
                                name='software'
                                ref='software'
                                :label='$t("lbl-software")'
                                :edit='false'
                                id='software'
                            />
                        </div>
                    </div>
                    <div class='w-full'>
                        <div class='w-full px-6'>
                            <FormInput
                                :value='currentFiduciary.contactEmail'
                                name='contactEmail'
                                ref='contactEmail'
                                :info='$t("info-contact-email")'
                                :label='$t("lbl-contact-email")'
                                :edit='edit'
                                rules='required|email|max:250'
                                id='contactEmail'
                                class='mb-4'
                            />
                            <FormSelect
                                :value='currentFiduciary.language'
                                name='language'
                                type='select'
                                :label='$t("lbl-language")'
                                :edit='edit'
                                id='language'
                                ref='language'
                                rules='required'
                                :options='[
                                    {
                                        label: $t("lbl-dutch"),
                                        value: "nl",
                                    },
                                    {
                                        label: $t("lbl-french"),
                                        value: "fr",
                                    }
                                ]'
                            />
                        </div>
                    </div>
                </div>
            </content-box>
        </Form>
    </div>
</template>

<style scoped>
label {
    @apply ml-0;
}
</style>
