<script>
    import { gql } from '@apollo/client/core';
    import FormInput from '@/components/FormInput';
    import FormToggle from '@/components/FormToggle';
    import FormSelect from '@/components/FormSelect';
    import Warning from './components/Warning.vue';
    import ImpactedCodaMandates from './components/ImpactedCodaMandates';
    import RequiredNotice from '@/components/RequiredNotice.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import EmailPill from '@/components/EmailPill.vue';
    import InsightInfo from '@/components/InsightInfo.vue';
    import Loader from '@/loader';
    import notify from '@/notify';
    import Translated from '@/components/Translated';
    import validate from '@/validate';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { Form } from 'vee-validate';

    export default {
        name: 'ClientInfo',
        props: {
            clientV2: {type: Object, required: true},
            accountant: {type: Object, required: true},
            currentFiduciary: {type: Object, required: true},
        },
        components: {
            FormInput,
            FormSelect,
            FormToggle,
            Warning,
            ImpactedCodaMandates,
            RequiredNotice,
            ContentBox,
            EmailPill,
            InsightInfo,
            FriendlyButton,
            Translated,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
        },
        data () {
            return {
                edit: false,
                saving: false,
                impactedCodaMandates: [],
                client: this.clientV2,
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
            isFiduciaryClient () {
                return this.client.enterpriseNumber === this.accountant.enterpriseNumber;
            },
            problems () {
                return this.$store.state.currentClientProblems;
            },
            hasExactProblem () {
                return this.problems.filter(p => p.type === 'exactOnlineDeliveryProblem').length > 0;
            },
            hasUnreachableContactEmailProblem () {
                return this.problems.filter(p => p.type === 'contactEmailUndeliverable').length > 0;
            },
        },
        watch: {
            clientV2: function (client) {
                this.client = client;
            },
        },
        emits: ['clientupdated'],
        mounted () {
            if (this.$route.query.showImpactedCodaMandatesPopup) {
                this.handleImpactedCodaMandates({}, true);
                // remove query param showImpactedCodaMandatesPopup to avoid nasty issues
                let updatedQuery = { ...this.$route.query };
                delete updatedQuery['showImpactedCodaMandatesPopup'];
                this.$router.replace({ query: updatedQuery });
            }
        },
        methods: {
            handleEdit () {
                this.edit = true;
            },
            handleCancel () {
                this.$refs.clientForm.resetForm();
                this.edit = false;
            },
            async handleImpactedCodaMandates (values, forceDisplay = false) {
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(
                                clientId:$clientId,
                                excludeMandatesWithoutBankAccounts:true,
                                states: [
                                    "available-online",
                                    "prepared",
                                    "sent-client",
                                    "problem",
                                ]
                            ) {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    isSignhereSupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                }
                                state
                                reasonCode
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                twikeyUrl
                                hasTeletransmissionPdf
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.client.id,
                    },
                });

                this.impactedCodaMandates = data.codaMandates;

                if (this.impactedCodaMandates.length > 0) {
                    let showImpactedPopup = false;
                    const sensibleFields = [
                        'enterpriseName',
                        'enterpriseNumber',
                        'address',
                        'address2',
                        'zip',
                        'city',
                        'contactEmail',
                        'language',
                        'representativeName',
                        'representativeFunction',
                    ];

                    for (let field of sensibleFields) {
                        if (this.client[field] !== values[field]) {
                            showImpactedPopup = true;
                            break;
                        }
                    }

                    this.$refs.impactedCodaMandates.show = showImpactedPopup || forceDisplay;
                }
            },

            async updateClientInfo (values) {
                if (this.saving) { return; }

                const loader = Loader.start();

                // some fields cannot be updated (readonly in backend),
                // but because we use Field to display them, it includes them in the Form.values
                // but graphql will refuse them, so be sure they are not there before providing the variables to graphql
                if ('hasBelgianVatNumber' in values) { delete values.hasBelgianVatNumber; }
                if ('enterpriseNumber' in values) { delete values.enterpriseNumber; }

                const input = {
                    ...values,
                    ...{
                        exactEmail: values.exactEmail || null,
                    },
                };

                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation updateClient($clientId: String!, $input: FiduciaryClientUpdateInput!) {
                            updateClient(clientId: $clientId, input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: {
                            clientId: this.client.id,
                            input,
                        },
                    });

                    if (data.updateClient.errors) {
                        validate.reportGQLFieldErrors(data.updateClient.errors, this.$refs.clientForm, {
                            'Client code already used for this fiduciary.': 'err-client-code-not-unique',
                            'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                        });
                    } else {
                        await this.handleImpactedCodaMandates(values);
                        this.client = {
                            ...this.client,
                            ...values,
                        };
                        this.$emit('clientupdated');
                        notify.success(this.$t('suc-client-modified'));
                        this.edit = false;
                    }

                    this.saving = false;
                    Loader.stop(loader);
                } catch (error) {
                    notify.error(this.$t('err-unknown'));
                    this.saving = false;
                    Loader.stop(loader);
                }
            },
        },
    };
</script>
<template>
    <Form
        ref='clientForm'
        class='client-subppage'
        id='clientInfo'
        @submit='updateClientInfo'
    >
        <ImpactedCodaMandates
            ref='impactedCodaMandates'
            :current-fiduciary='currentFiduciary'
            :mandates='impactedCodaMandates'
            @refresh-coda-mandates='handleImpactedCodaMandates'
        />
        <content-box :title='$t("h-client-info")'>
            <template #actions>
                <div v-if='!edit' class='client-subppage-header__actions__edit'>
                    <FriendlyButton
                        label='btn-edit-client-info'
                        symbol='pencil'
                        :action='handleEdit'
                        square
                        extra-small
                        no-margin
                    />
                </div>
                <template v-else>
                    <FriendlyButton
                        label='btn-cancel'
                        type='reset'
                        :action='() => { handleCancel() }'
                        symbol='times'
                        secondary
                        square
                        extra-small
                        no-margin
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-save'
                        type='submit'
                        symbol='check'
                        square
                        extra-small
                        no-margin
                    />
                </template>
            </template>
            <div
                v-if='isFiduciaryClient && edit'
                class='text-white mb-3 px-4 py-2 bg-orange-300 inline-block rounded-md flex items-center mb-6'
                id='warning-only-edit-partial-info'
            >
                <i class='fa fa-exclamation-triangle mr-3'></i>
                <Translated>
                    <template #en>
                        The other info can be updated via Administration - Organization info
                    </template>
                    <template #nl>
                        De overige info kan bijgewerkt worden via Administratie - Organisatie info
                    </template>
                    <template #fr>
                        Les autres infos peuvent être mises à jour via Administration - Infos de l'organisation
                    </template>
                </Translated>
            </div>
            <div class='grid grid-cols-3 gap-6 mb10'>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='client.clientCode'
                            name='clientCode'
                            ref='clientCode'
                            :label='$t("lbl-client-code")'
                            :placeholder='$t("lbl-client-code")'
                            :edit='edit'
                            rules='required|max:50'
                            id='clientCode'
                        />
                        <InsightInfo
                            :has-problem='hasUnreachableContactEmailProblem'
                        >
                            <FormInput
                                :value='client.contactEmail'
                                type='email'
                                name='contactEmail'
                                ref='contactEmail'
                                :label='$t("lbl-client-email")'
                                :placeholder='$t("lbl-email")'
                                :edit='edit'
                                rules='required|email|max:250'
                                id='contactEmail'
                            >
                                <template #readOnlyContent>
                                    <EmailPill
                                        :email='client.contactEmail'
                                        :client-id='client.id'
                                        used-copys='contactEmail'
                                        class='w-full'
                                    />
                                </template>
                            </FormInput>
                        </InsightInfo>
                        <FormSelect
                            :value='client.language'
                            name='language'
                            type='select'
                            :label='$t("lbl-language")'
                            :placeholder='$t("lbl-language-select")'
                            :edit='edit'
                            id='language'
                            rules='required'
                            :options='[
                                {
                                    label: $t("lbl-dutch"),
                                    value: "nl",
                                },
                                {
                                    label: $t("lbl-french"),
                                    value: "fr",
                                },
                                {
                                    label: $t("lbl-english"),
                                    value: "en",
                                }
                            ]'
                        />
                        <InsightInfo
                            :has-problem='hasExactProblem'
                            v-if='isFiduExactOnline || client.exactEmail'
                        >
                            <FormInput
                                :value='client.exactEmail'
                                name='exactEmail'
                                ref='exactEmail'
                                type='email'
                                :label='$t("lbl-exact-email")'
                                :placeholder='$t("lbl-exact-email")'
                                :disabled='!isFiduExactOnline'
                                :edit='edit'
                                nullable
                                id='exactEmail'
                                rules='exactEmail|max:250'
                            />
                        </InsightInfo>
                    </div>
                </div>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='client.enterpriseName'
                            ref='enterpriseName'
                            name='enterpriseName'
                            :label='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            :edit='client.enterpriseNumber && !isFiduciaryClient && edit'
                            id='enterpriseName'
                            rules='required|max:160'
                        />
                        <FormInput
                            :value='client.enterpriseNumber'
                            :label='$t("lbl-enterpriseNumber")'
                            id='enterpriseNumber'
                            name='enterpriseNumber'
                        />

                        <FormToggle
                            name='hasBelgianVatNumber'
                            :value='client.hasBelgianVatNumber'
                            :label='$t("lbl-has-belgian-vat-number")'
                            disabled
                            id='hasBelgianVatNumber'
                            class='my-2'
                        />
                        <FormInput
                            :value='client.representativeName'
                            name='representativeName'
                            :label='$t("lbl-representative-name")'
                            :placeholder='$t("placeholder-representative-name")'
                            rules='required|max:160|composedName'
                            :edit='!isFiduciaryClient && edit'
                            id='representativeName'
                            :info='$t("p-tooltip-representative-name")'
                        />
                        <FormInput
                            :value='client.representativeFunction'
                            name='representativeFunction'
                            :label='$t("lbl-representative-function")'
                            :placeholder='$t("lbl-representative-function")'
                            :edit='!isFiduciaryClient && edit'
                            id='representativeFunction'
                            rules='required|max:128'
                        />
                    </div>
                </div>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='client.address'
                            name='address'
                            :label='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            rules='required|max:108'
                            :edit='!isFiduciaryClient && edit'
                            id='address'
                        />
                        <FormInput
                            :value='client.address2'
                            name='address2'
                            rules='max:100'
                            :placeholder='$t("lbl-address-line-2")'
                            :edit='!isFiduciaryClient && edit'
                            id='address2'
                        />
                        <FormInput
                            :value='client.zip'
                            name='zip'
                            :label='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            rules='required|min:4|max:20'
                            :edit='!isFiduciaryClient && edit'
                            id='zip'
                        />
                        <FormInput
                            :value='client.city'
                            name='city'
                            :label='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            rules='required|max:50'
                            :edit='!isFiduciaryClient && edit'
                            id='city'
                        />
                    </div>
                </div>
            </div>

            <div>
                <!-- email warning when delivery mode is email -->
                <Warning
                    v-if='$refs.contactEmail?.hasFocus &&
                        currentFiduciary.sendCodaAndSodaByMail &&
                        client.sendCodaAndSodaByMail'
                >
                    <template #header>
                        {{ $t('h-warn-email-changes') }}
                    </template>
                    {{ $t('warn-email-accurate') }} <br><br>
                    {{ $t('warn-email-accurate-2') }}
                </Warning>

                <!-- exact email warning -->
                <Warning v-if='$refs.exactEmail?.hasFocus && !isFiduExactOnline'>
                    <template #header>
                        {{ $t('h-exact-email-warning') }}
                    </template>
                    {{ $t('p-exact-email-warning') }}
                </Warning>

                <!-- client code warning -->
                <Warning v-if='$refs.clientCode?.hasFocus' id='clientCodeWarning'>
                    <template #header>
                        {{ $t('h-client-code-warning') }}
                    </template>
                    {{ $t('p-client-code-warning') }}
                </Warning>

                <!-- enterprise name warning -->
                <Warning v-if='$refs.enterpriseName?.hasFocus' id='enterpriseNameWarning'>
                    <template #header>
                        {{ $t('h-enterprise-name-warning') }}
                    </template>
                    {{ $t('p-enterprise-name-warning') }}
                </Warning>
            </div>

            <RequiredNotice class='mt-3' v-if='edit' />
        </content-box>
    </Form>
</template>

<style lang="scss" scoped>
.client-subppage {
    min-height: calc(100vh - 450px);
}

.client-subppage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}

.client-subppage-header .btn+.btn {
    @apply ml-3;
}

.client-page__settings {
    margin-top: 20px;
}

.client-subppage-subtitle {
    margin: 40px 0 20px 0;
}

.client-subppage-header__actions {
    @apply flex items-center ml-auto;
}
.client-subppage-header__actions__edit {
    display: flex;
    align-items: center;
    color: $warning-color;

    .cb-legend {
        opacity: 1;
        text-decoration: underline;
    }

    .cb-legend-body {
        color: $color-grey-dark;
    }
}

.client-subppage-header__actions__edit__notice {
    margin-right: 10px;
}
</style>
